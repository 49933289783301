jQuery(function($) {

    // GHEAjax is required to continue
    if (typeof GHEAjax === 'undefined') {
        return false;
    }

    /**
     * Object to handle multiselect UI.
     */
    var multiSelect = {

        multiSelects: false,
        multiSelectForm: $('form.multiselect-form'),
        canClearAll: false,

        /**
         * Initialize multiselect UI events
         */
        init: function() {

            // Get all multiselects
            this.initMultiSelects();

            // Toggle a multiselect
            this.multiSelectForm.on('mousedown touchend focusin focusout', '.trigger', this.toggleMultiSelect);

            // Form submission
            this.multiSelectForm.on('submit', this.multiSelectSubmit);
            
            // Close all multiselects
            $(document.body).on('mouseup', this.closeAllMultiSelects);

            // Check user has scrolled to bottom of the list
            $('.list').bind('scroll', this.isScrolledToBottom);
            
            // Search filter for multiselect
            $(document.body).on('keyup', 'input[name="multiselect-search"]', this.searchMultiSelect);
            
            // Apply filter choices to a multiselect
            $(document.body).on('click', '.apply', this.applyMultiSelect);

            // Clear any filter choices for a multiselect
            $(document.body).on('click', '.clear', this.clearMultiSelect);

            // Clear filters from all multiselects
            $(document.body).on('click', '.clear-all', this.clearAllMultiSelects);

            // Apply checkbox selection(s)
            $(document.body).on('change', 'input[type^=checkbox]', this.setCheckedOptions);

            // Apply checkbox selection(s)
            $(document.body).on('change', 'input[type^=checkbox]', this.applyCheckedOptions);

            // Toggle display of a multiselect children list
            $(document.body).on('click', '.children-toggle', this.toggleMultiSelectChildren);
        },

        /**
         * Get all multiselects in the form.
         */
        getMultiSelects: function() {
            multiSelect.multiSelects = multiSelect.multiSelectForm.find('.multiselect');
            return multiSelect.multiSelects;
        },

        /**
         * @TODO
         */
        initMultiSelects: function() {
            var $multiSelects = multiSelect.getMultiSelects();
            [].forEach.call($multiSelects, function(multiselect) {
            });
        },

        /**
         * @TODO
         */
        extendMultiSelects: function() {
            $('.clear-all').removeClass('active');
            $('.archive-search input[name="search_query"]').val('');
            location.reload();
        },

        /**
         * Toggle a multiselect.
         */
        toggleMultiSelect: function(evt) {
            var state = $(this).data('state') || 0;
            multiSelect.closeMultiSelect(evt);
            if (state == 0) {
                $(this).data('state', '1').addClass('open').removeClass('closed');
                $(this).find('.fas').addClass('fa-chevron-up').removeClass('fa-chevron-down');
                $(this).parent().find('.contents, .search').show();
                multiSelect.selectedMultiSelect = $(this).parent();
                multiSelect.multiSelectCanScroll(multiSelect.selectedMultiSelect);
            } else {
                $(this).data('state', '0').addClass('closed').removeClass('open');
                $(this).find('.fas').addClass('fa-chevron-down').removeClass('fa-chevron-up');
                $(this).parent().find('.contents, .search').hide();
            }
            evt.preventDefault();
        },

        /**
         * Close a multiselect.
         */
        closeMultiSelect: function(evt) {
            if ($('.trigger').hasClass('open')) {
                $('.trigger').data('state', '0').removeClass('open');
                $('.trigger').find('.fas').removeClass('fa-chevron-up').addClass('fa-chevron-down');
                $('.trigger').parent().find('.contents, .search').hide();
            }
        },

        /**
         * Close all multiselects.
         */
        closeAllMultiSelects: function(evt) {
            if ($('.trigger').hasClass('open')) {
                var elements = '.trigger, .contents, .search, .apply';
                if (!$(elements).is(evt.target) && $(elements).has(evt.target).length === 0) {
                    $('.trigger').data('state', '0').removeClass('open');
                    $('.trigger').find('.fas').removeClass('fa-chevron-up').addClass('fa-chevron-down');
                    $('.contents, .search').hide();
                }
            }
        },

        /**
         * Toggle a multiselect.
         */
        isScrolledToBottom: function(evt, off) {
            var $el = $(this);
            if ($el[0].scrollHeight - $el.scrollTop() - $el.outerHeight() - (off ? off : 20) < 1) {
                $el.addClass('at-bottom');
            } else {
                $el.removeClass('at-bottom');
            }
        },

        /**
         * Toggle a multiselect.
         */
        multiSelectCanScroll: function(multiselect, off) {
            var $el = multiselect.find('.list');
            if ($el[0].scrollHeight - $el.scrollTop() - $el.outerHeight() - (off ? off : 20) < 1) {
                $el.addClass('at-bottom');
            } else {
                $el.removeClass('at-bottom');
            }
        },

        /**
         * Clear a multiselect checked options.
         *
         * @param {Object} multiselect The multiselect JQuery object
         */
        clearCheckedOptions: function(multiselect) {
            var checkedOptions = multiselect.find('input[type^=checkbox]:checked');
            checkedOptions.each(function() {
                $(this).prop('checked', false);
            });
        },

        /**
         * Get the currently checked options for a multiselect
         *
         * @param {Object} multiselect The multiselect JQuery object
         */
        getCheckedOptions: function(multiselect) {
            return multiselect.find('input[type^=checkbox]:checked');
        },

        /**
         * Set the checked options for a multiselect
         */
        setCheckedOptions: function(evt) {
            var parentList = $(this).closest('ul');
            if (parentList.hasClass('list-children')) {
                var parentLink = $(this).closest('.has-children');
                var parentOption = parentLink.find('input[type^=checkbox]').eq(0);
                if (parentOption.is(':checked')) {
                    var childrenCheckedOptions = parentList.find('input[type^=checkbox]:checked');
                    if (! childrenCheckedOptions.length) {
                        parentOption.prop('checked', false);
                    }
                } else {
                    parentOption.prop('checked', true);
                }
            } else {
                var childrenList = $(this).siblings('.list-children').eq(0);
                var childrenOptions = childrenList.find('input[type^=checkbox]:checked');
                childrenOptions.each(function() {
                    $(this).prop('checked', false);
                });
            }
        },

        /**
         * Apply the currently checked options for a multiselect.
         */
        applyCheckedOptions: function(evt) {
            evt.preventDefault();
            var multiselect = $(evt.currentTarget).closest('.multiselect');
            var multiSelectType = multiSelect.multiSelectType(multiselect);
            if (multiSelect.multiSelectHasSearch(multiselect)) {
                multiSelect.multiSelectClearSearch(multiselect);
            }
            multiSelect.multiSelectHandler(multiselect, 'applyMultiSelect');
        },

        /**
         * Clear the current selected.
         */
        clearCurrentSelection: function(title, selected) {
            if (selected != null) {
                title.removeClass('d-none');
                selected.addClass('d-none');
                selected.find('span').text('');
            }
        },

        /**
         * Get the current selected.
         *
         * @param 
         */
        getCurrentSelection: function(qty, type) {
            //var multiSelectTypeStr = (qty > 1) ? type + 's' : type;
            var multiSelectTypeStr = (qty > 1) ? SkillsForWalesl10n.strings[type][1] : SkillsForWalesl10n.strings[type][0];
            //return qty + ' ' + multiSelectTypeStr + ' selected';
            if (SkillsForWalesl10n.current_language === 'en') {
                return multiSelectTypeStr.replace('%d', qty);
            } else {
                return multiSelectTypeStr.replace('&d', qty);
            }
        },

        /**
         * Set the current selected.
         */
        setCurrentSelection: function(title, selected, current) {
            if (selected != null) {
                title.addClass('d-none');
                selected.removeClass('d-none');
                selected.find('span').text(current);
            }
        },

        /**
         * Apply a multiselect.
         */
        applyMultiSelect: function(evt) {
            evt.preventDefault();
            var multiselect = $(evt.currentTarget).closest('.multiselect');
            var multiSelectType = multiSelect.multiSelectType(multiselect);
            if (multiSelect.multiSelectHasSearch(multiselect)) {
                multiSelect.multiSelectClearSearch(multiselect);
            }
            multiSelect.closeMultiSelect();
            multiSelect.multiSelectHandler(multiselect, 'applyMultiSelect');
        },

        /**
         * Clear a multiselect.
         */
        clearMultiSelect: function(evt) {
            evt.preventDefault();
            var multiselect = $(evt.currentTarget).closest('.multiselect');
            var multiSelectType = multiSelect.multiSelectType(multiselect);
            multiSelect.clearCheckedOptions(multiselect);
            multiSelect.multiSelectClearSearch(multiselect);
            multiSelect.clearTypeArray(multiSelectType);
            multiselect.find('.list li').removeClass('d-none');
            multiSelect.multiSelectHandler(multiselect, 'clearMultiSelect');
        },

        /**
         * Clear all multiselects.
         */
        clearAllMultiSelects: function(evt) {
            evt.preventDefault();
            $('.clear').trigger('click');
            if (GHEAjax.hasQueryVars()) {
                multiSelect.multiSelects.each(function(){
                    var multiSelectType = multiSelect.multiSelectType($(this));
                    multiSelect.clearTypeArray(multiSelectType);
                    GHEAjax.removeQueryVariable(multiSelectType + '_ids');
                });
            }
            multiSelect.extendMultiSelects();
        },

        /**
         * Toggle a multiselect children list.
         */
        toggleMultiSelectChildren: function(evt) {
            evt.preventDefault();
            var multiselect = $(evt.currentTarget).closest('.multiselect');
            $(this).find('.fas').toggleClass('fa-plus-circle fa-minus-circle');
            $(this).toggleClass('open').next().toggle();
            multiSelect.multiSelectCanScroll(multiselect);
        },

        /**
         * Search filter a multiselect.
         */
        searchMultiSelect: function(evt) {
            evt.preventDefault();
            var multiSearch = $(evt.currentTarget);
            var multiselect = multiSearch.closest('.multiselect');
            var selectItems = multiselect.find('.list li');
            if (multiSearch != null) {
                var text = evt.target.value,
                    pattern = new RegExp(text, 'i');
                if (selectItems != null) {
                    selectItems.each(function(){
                        var postcode = $(this).data('postcode');
                        if (pattern.test($(this).text()) || pattern.test(postcode)) {
                            $(this).removeClass('d-none');
                        } else {
                            $(this).addClass('d-none');
                        }
                    });
                }
            }
        },

        /**
         * Conditional check if the multiselect has a search input
         *
         * @param {Object} multiselect The multiselect JQuery object
         */
        multiSelectHasSearch: function(multiselect) {
            return multiselect.find('.search').length;
        },

        /**
         * Clear the multiselect search input
         *
         * @param {Object} multiselect The multiselect JQuery object
         */
        multiSelectClearSearch: function(multiselect) {
            multiselect.find('.search input[name="multiselect-search"]').val('');
        },

        /**
         * Handle multiselect form submit and route to correct logic.
         *
         * @param {Object} evt The JQuery event
         */
        multiSelectSubmit: function(evt) {
            multiSelect.multiSelects.each(function(){
                var multiSelectType = multiSelect.multiSelectType($(this));
                $('input[name="' + multiSelectType + 's[]"]').attr('disabled', true);

                var typeIdList = $('input[name="' + multiSelectType + '_ids"]');
                if (typeIdList.val() === '') {
                    $('input[name="' + multiSelectType + '_ids"]').attr('disabled', true);
                }
            });
            $('input[name="multiselect-search"]').attr('disabled', true);
            $('input[name="search_query"]').attr('disabled', true);
        },

        /**
         * Retrieve and set the multiselect entity type.
         *
         * @param {Object} multiselect The multiselect JQuery object
         */
        multiSelectType: function(multiselect) {
            return multiselect.attr('data-type');
        },

        /**
         * Define the multiselect entity type array.
         *
         * @param {String}
         */
        defineTypeArray: function(type) {
            var typeArray = type + '_array';
            typeArray = [];
            return typeArray;
        },

        /**
         * Build the multiselect entity type array.
         *
         * @param  
         * @param string 
         */
        buildTypeArray: function(options, type) {
            var typeArray = multiSelect.defineTypeArray(type);
            options.each(function() {
                typeArray.push($(this).val());
            });
            return typeArray;
        },

        /**
         * Parse the multiselects entity type array.
         *
         * @param array
         * @param string 
         */
        parseTypeArray: function(typeArray, type) {
            //var hiddenInput = $('#' + type + '_ids');
            var hiddenInput = $('input[name="' + type + '_ids"]');
            if (hiddenInput != null) {
                if (JSON.stringify(typeArray, null, ' ') !== '[]') {
                    //var ids = JSON.stringify(typeArray);
                    var ids = typeArray.join(',');
                    hiddenInput.val(ids);
                }
            }
        },

        /**
         * Clear the multiselects entity type array.
         *
         * @param string 
         */
        clearTypeArray: function(type) {
            var hiddenInput = $('input[name="' + type + '_ids"]');
            if (hiddenInput != null) {
                hiddenInput.val('');
            }
        },

        /**
         * Main handler for the multiselect dropdowns.
         *
         * @param {Object} multiselect The multiselect JQuery object
         * @param 
         */
        multiSelectHandler: function(multiselect, called) {

            var multiSelectType     = multiSelect.multiSelectType(multiselect);
            var multiSelectTitle    = multiselect.find('.title');
            var multiSelectSelected = multiselect.find('.selected');

            switch (called) {
                case 'clearMultiSelect':
                    multiSelect.clearCurrentSelection(multiSelectTitle, multiSelectSelected);
                    multiselect.trigger('clear');
                    break;
                case 'applyMultiSelect':
                    var checkedOptions = multiSelect.getCheckedOptions(multiselect);
                    var qtyCheckedOptions = checkedOptions.length;
                    if (qtyCheckedOptions >= 1) {
                        var currentSelected = multiSelect.getCurrentSelection(qtyCheckedOptions, multiSelectType);
                        multiSelect.setCurrentSelection(multiSelectTitle, multiSelectSelected, currentSelected);
                        var typeArray = multiSelect.buildTypeArray(checkedOptions, multiSelectType);
                        multiSelect.parseTypeArray(typeArray, multiSelectType);
                    } else {
                        multiSelect.clearCurrentSelection(multiSelectTitle, multiSelectSelected);
                        multiSelect.clearTypeArray(multiSelectType);
                    }
                    multiselect.trigger('apply');
                    break;
                default:
            }
        }
    };

    multiSelect.init();
    window.multiSelect = multiSelect;
});
