/**
 * Menu and Mavigation Element Handlers.
 *
 * @author John Ranby
 * @see    https://github.com/jranby
 */
jQuery(document).ready(function($) {

    /*
     * Menu Toggle Click Hanglefr
     *
     * Bacon ipsum dolor amet tenderloin cow tongue,
     * filet mignon kielbasa brisket salami biltong.
     */
    $('.toggle-menu').on('click', function(e) {
        e.preventDefault();
        $(this).children('i').eq(0).toggleClass('fa-bars fa-times');
        $('body').toggleClass('menu-open');
        $('.mobile-menu-container').toggleClass('d-none');
    });

    // ---------------------------------------------------------------------

    /*
     * Cache Current Window Wisth
     */
    var cachedWidth = $(window).width();

    // ---------------------------------------------------------------------

    /*
     * Resize Handler
     *
     * Bacon ipsum dolor amet tenderloin cow tongue,
     * filet mignon kielbasa brisket salami biltong.
     */
    $(window).resize(function() {
        var newWidth = $(window).width();
        if (newWidth !== cachedWidth){
            if ($('body').hasClass('menu-open')) {
                $('body').removeClass('menu-open');
                $('.mobile-menu-container').toggleClass('d-none');
                var toggler = $('.toggle-menu i');
                if (toggler.hasClass('fa-times')) {
                    toggler.toggleClass('fa-bars fa-times');
                }
            }
            cachedWidth = newWidth;
        }
    });

    // ---------------------------------------------------------------------

    /*
     * Off Sidebar Click To Close Handling
     *
     * Bacon ipsum dolor amet tenderloin cow tongue,
     * filet mignon kielbasa brisket salami biltong.
     */
    $(document).mouseup(function(evt) {
        if ($('body').hasClass('off-canvas-open')) {
            var elements = '.menu, .menu-toggle, .off-canvas, .menu-socials, .off-canvas-search';
            if (! $(elements).is(evt.target) && $(elements).has(evt.target).length === 0) {
                $('.off-canvas').removeClass('open');
                $('body').removeClass('off-canvas-open');
            }
        }
    });
});
